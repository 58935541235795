/**
 * 判断用户是否拥有操作权限
 * 根据传入的权限标识，查看是否存在用户权限标识集合
 * @param perms
 */
let hasPermissionBol = false

function recursion(permissions, type) {
  for (let i = 0, len = permissions.length; i < len; i++) {
    if (permissions[i].apiPath == type) {
      hasPermissionBol = true
      break
    }
    if (permissions[i].children.length) {
      recursion(permissions[i].children, type)
    }
  }
}

export function hasPermission(perms) {
  hasPermissionBol = false
  let permissions = JSON.parse(sessionStorage.getItem('actions'))
  let type = perms
  
  if (permissions) {
    recursion(permissions, type)
  }
  return hasPermissionBol
}
