<template>
  <el-menu
    class="h-v"
   
    :collapse="isCollapse"
    :collapse-transition="false"
    router
    unique-opened
    :default-active="activePath"
  >
    <div class="tittle">
      <div class="logo_box">
        <img src="../../../assets/image/logo_top34.png" alt="" />
        <!-- <img class="tittle_pic" src="../../../assets/image/system-tittle.png" alt="" /> -->
      </div>
    </div>
    <template v-for="item in menuList">
      <!-- 一级菜单 -->
      <router-link :to="item.url" :key="item.id+'o'" v-if="item.children.length < 1">
        <el-menu-item :index="item.url + ''">
          <i :class="item.icon" class="iconfont" style="margin-right: 10px;"></i>
        
          <span style="font-weight:550 ;font-size: 17px;">{{ item.authName }}</span>

          <!-- <span class="name_zhican">{{ item.authName }}</span> -->
        </el-menu-item>
      </router-link>
      <el-submenu v-else :key="item.id +'-'" :index="item.url + ''">
        <!-- 一级菜单模板区域 -->
        <template slot="title">
          <!-- 图标 -->
          <i :class="item.icon" class="iconfont"></i>
          <!-- 文本 -->
          <!-- <span class="name_zhican">{{ item.authName }}</span> -->
          <span style="font-weight:550 ;font-size: 17px;">{{ item.authName }}</span>

        </template>
        <!-- 二级菜单 -->
        <el-menu-item v-for="subItem in item.children" :key="subItem.id+'-'" :index="subItem.url">
          <template slot="title">
            <!-- 图标 -->
            <i :class="subItem.icon" />
            <!-- 文本 -->
            <span style="margin-left: 5px;">{{ subItem.authName }}</span>
          </template>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '', // 当前路由
      menuList: [
        // {
        //   id: 0,
        //   authName: '工作台',
        //   url: '/workbench',
        //   icon: 'iconfont icon-jr-icon-dashboard'
        // },
        {
          id: 1,
          authName: '客户经理',
          url: '/customermanager',
          icon: 'iconfont icon-jr-icon-uers'
        },
        {
          id: 2,
          authName: '企业客户',
          url: '/enterprisecustomer',
          icon: 'iconfont icon-jr-icon-enterprise'
        },
        {
          id: 11,
          authName: '系统管理',
          url: '/system/banner',
          icon: 'iconfont icon-jr-icon-seven',
          children: [
            {
              id: 111,
              authName: 'banner管理',
              url: '/system/banner',
              icon: 'iconfont icon-jr-icon-seven'
            },
            {
              id: 112,
              authName: '系统用户',
              url: '/system/user',
              icon: 'iconfont icon-jr-icon-seven'
            },
            {
              id: 113,
              authName: '权限管理',
              url: '/system/rights',
              icon: 'iconfont icon-jr-icon-seven'
            },
            {
              id: 114,
              authName: '菜单中心',
              url: '/system/menumanagement',
              icon: 'iconfont icon-jr-icon-seven'
            }

            // {
            //   id: 115,
            //   authName: '日志管理',
            //   url: '/system/logManagement',
            //   icon: 'iconfont icon-jr-icon-seven'
            // }
          ]
        }
      ]
    }
  },
  watch: {
    $route() {
      // 监听路由变化，将最新路由存入浏览器缓存
      let path = this.$route.path
      
      let arr = path.toString().split('/')
      const menuPath = arr[1]
      // console.log(menuPath)
      if (menuPath == 'system') {
        // 菜单为 系统管理 时拿第二级
        path = '/system/' + arr[2]
      } else {
        // path只接收第二级
        if (arr.length > 2) {
          // console.log(arr);zs
          
          path = arr.join('/')
          // console.log(path);
          
        }
      }
      // console.log(path)
      this.$nextTick(() => {
        this.activePath = path
      })
      sessionStorage.setItem('activePath', path)
    }
  },
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem('actions'))
    let path = sessionStorage.getItem('activePath')
    if (path) {
      this.activePath = path
    } else {
      this.activePath = '/workbench'
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu ::v-deep .is-active {
  background-color: #f0f5ff !important;
}
.el-menu-item i {
  // margin-left: 20px;
  margin-right: 5px;
}
.iconfont {
  width: 20px;
  display: inline-block;
  color: #212121;
 margin-right: 10px;
}

.name_zhican{
    font-family: 'YouSheBiaoTiYuan';
font-weight: 900;
font-size: 16px;
color: #333333;
margin-left: 5px;
}
.tittle {
  display: flex;
  align-items: center;
  height: 56px;
  line-height: 56px;
  padding-left: 8px;
  font-family: 'YouSheBiaoTiHei';
  font-style: normal;
  font-size: 20px;
  .logo_box {
    display: flex;
    align-items: center;
    width: 120px;
    height: 35px;
    margin-right: 4px;
    margin-left: 22px;
    // box-shadow: 0px 0px 8px rgba(78, 110, 242, 0.2);
    // border-radius: 2px;
    img {
      width: 130px;
    height: 40px;
      margin-right: 4px;
      // box-shadow: 0px 0px 8px rgba(78, 110, 242, 0.2);
    }
    .tittle_pic {
      width: 160px;
      height: 16px;
      box-shadow: none;
    }
  }
}
</style>
