<template>
  <el-container class="h-v">
    <el-aside width="200px">
      <Aside></Aside>
    </el-aside>
    <el-container>
      <el-header>
        <hy-header></hy-header>
      </el-header>
      <el-main class="hy-main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import RouterView from '../components/RouterView.vue'
import Aside from './components/Aside'
import HyHeader from './components/HyHeader'
export default {
  components: { RouterView, Aside, HyHeader }
}
</script>

<style lang="less" scoped>
.hy-main {
  background: #f6f7fb;
}
</style>
