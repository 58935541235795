import request from '@/utils/request'

// 图片、文件上传
export function uploadAdmin(data) {
  return request({
    url: '/publicUpload/uploads',
    method: 'post',
    data
  })
}

//codeType查询表
export function getCode(data) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params: data
  })
}

// 查询城市
export function getCityAll(data) {
  return request({
    url: '/china/getCityAll',
    method: 'get',
    params: data
  })
}

//单文件上传
export function apiUpload(data) {
  return request({
    url: '/public/apiUpload',
    method: 'POST',
    data
  })
}

//查询标签
export function getLabel() {
  return request({
    url: '/label/getLabel',
    method: 'get'
  })
}

//查询子标签
export function getChild(query) {
  return request({
    url: '/label/getChild',
    method: 'get',
    params: query
  })
}

//新增智参企业标签
export function savelabel(data) {
  return request({
    url: '/serverCompany/insertCompanyLabel',
    method: 'post',
    data: data
  })
}

//编辑智参企业标签
export function updateInformationLabel(data) {
  return request({
    url: '/serverCompany/updateCompanyLabel',
    method: 'post',
    data: data
  })
}

//获取智参企业标签信息详情
export function getBylabelId(query) {
  return request({
    url: '/serverCompany/getById',
    method: 'get',
    params: query,
  })
}

//根据关键字模糊查询智参中心数据
export function getWisdomGinsengList(query) {
  return request({
    url: "/wisdomGinseng/getWisdomGinsengList",
    method: "get",
    params: query
  })
}

//城市
export function getByFatherIdt(query) {
  return request({
    url: '/city/getAllCity',
    method: 'get',
    params: query
  })
}

//省份
export function getProvince() {
  return request({
    url: '/city/getCityAll',
    method: 'get'
  })
}
